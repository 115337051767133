import React, {HTMLAttributes, PropsWithChildren, ReactNode} from 'react';
import BaseLayout from "@/Layouts/BaseLayout";
import Container from "@/Components/Container";
import Header from "@/Components/Header";
import Footer from "@/Components/Footer";
import clsx from "clsx";

import '../../css/guest.css';

interface GuestProps extends PropsWithChildren<HTMLAttributes<HTMLDivElement>> {
    fill?: boolean;
    expanded?: boolean;
    headerOverride?: ReactNode;
}

export default function Guest({className = '', fill = true, children, expanded = false, headerOverride = null}: GuestProps) {
    return (
        <BaseLayout>
            <Container expanded={expanded} className={clsx(fill && "flex flex-col min-h-screen", className)}>
                <div>
                    <Header accountControlOverride={headerOverride}/>
                </div>

                <div className="grow">
                    {children}
                </div>

                <div>
                    <Footer/>
                </div>
            </Container>
        </BaseLayout>
    );
}
